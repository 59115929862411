export const environment = {
  production: true,
  isLive: false,
  skip_validation: false,
  file_upload: false,
  planDisable: true,

  API_ENDPOINT: "https://devbackend.ozdingo.com.au/home/public/",

  AppName: "Ozdingo Connect",
  helpFBPdfLink:
    "https://docs.cedcommerce.com/wp-content/uploads/2020/03/FB-Solution_User-Manual-Doc.pdf",
  createBusinessManager: "https://business.facebook.com/overview",
  appURL: "",
  APP_API_KEY: "8e58c700f1ecc2539682f6a04a8852c7",
  Bearer:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VyX2lkIjoiMSIsInJvbGUiOiJhcHAiLCJpYXQiOjE1MzkwNTk5NzgsImlzcyI6Imh0dHBzOlwvXC9hcHBzLmNlZGNvbW1lcmNlLmNvbSIsImF1ZCI6ImV4YW1wbGUuY29tIiwibmJmIjoxNTM5MDU5OTc4LCJ0b2tlbl9pZCI6MTUzOTA1OTk3OH0.GRSNBwvFrYe4H7FBkDISVee27fNfd1LiocugSntzxAUq_PIioj4-fDnuKYh-WHsTdIFMHIbtyt-uNI1uStVPJQ4K2oYrR_OmVe5_zW4fetHyFmoOuoulR1htZlX8pDXHeybRMYlkk95nKZZAYQDB0Lpq8gxnTCOSITTDES0Jbs9MENwZWVLfyZk6vkMhMoIAtETDXdElIdWjP6W_Q1kdzhwqatnUyzOBTdjd_pt9ZkbHHYnv6gUWiQV1bifWpMO5BYsSGR-MW3VzLqsH4QetZ-DC_AuF4W2FvdjMRpHrsCgqlDL4I4ZgHJVp-iXGfpug3sJKx_2AJ_2aT1k5sQYOMA",
};
/* Bearer on the last line is App token for server */
